.pdf-container {
    width: 100%; /* Adjust the width to fit your layout */
    height: 500px; /* Adjust the height as needed */
    overflow: hidden;
    position: relative;
  }
  
  .pdf-image {
    width: 100%;
    height: 100%;
    transition: transform 0.3s;
  }
  
  .pdf-image:hover {
    transform: translateX(20px); /* Adjust the scroll distance */
    transition: transform 0.3s;
  }
  
  .card-title-custom {
    color: #05aaaa;
    font-size: 24px !important; 
    font-weight: bold; /* Example font weight */
    font-family: "Arial, sans-serif"; 
}